import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@toast-ui/react-editor';

import InsertStatsButton from './editorButtons/InsertStatsButton';
import InsertQuoteButton from './editorButtons/InsertQuoteButton';
import InsertLineChartButton from './editorButtons/InsertLineChartButton';
import InsertBarChartButton from './editorButtons/InsertBarChartButton';
import InsertAlertButton from './editorButtons/InsertAlertButton';
import PreviewButton from './editorButtons/PreviewButton';

import compile from '@mdx-js/mdx'

const MarkdownEditor = ({ markdown, name }) => {
  const [editorMarkdown, setEditorMarkdown] = useState(markdown);
  const [error, setError] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    setEditorMarkdown(markdown);
  }, [markdown]);

  const handleEditorChange = () => {
    const newMarkdown = editorRef.current.getInstance().getMarkdown();
    checkValidJSX(newMarkdown)
    setEditorMarkdown(newMarkdown);
  };

  const checkValidJSX = async (input) => {
    input = input.replace(/\\</g, '<').replace(/<br>/g, '<br />');
    try {
      await compile(input);
      setError("");
    } catch (e) {
      console.log(e)
      setError(e.message);
    }
  }

  return (
    <div>
      <div className="tw-flex tw-justify-start tw-w-full tw-border-gray-300 tw-rounded-xl tw-bg-[#F7F9FC] tw-p-4 tw-space-x-10 tw-pl-10 tw-mb-4 tw-border-[1px] tw-border-solid">
        <InsertQuoteButton editorRef={editorRef} />
        <InsertAlertButton editorRef={editorRef} />
        <InsertStatsButton editorRef={editorRef} />
        <InsertLineChartButton editorRef={editorRef} />
        <InsertBarChartButton editorRef={editorRef} />
        <PreviewButton editorRef={editorRef} />
      </div>
      <Editor
        initialValue={markdown}
        ref={editorRef}
        previewStyle="vertical"
        previewHighlight={false}
        hideModeSwitch={true}
        frontMatter={true}
        placeholder="Write your markdown here!"
        height="1000px"
        initialEditType="wysiwyg"
        onChange={handleEditorChange}
        plugins={[]}
        toolbarItems={[
          ['heading', 'bold', 'italic', 'strike'],
          ['hr'],
          ['ul', 'ol', 'indent', 'outdent'],
          ['table', 'image', 'link']
        ]}
      />
      <textarea
        name={name}
        value={editorMarkdown}
        style={{ display: 'none' }}
        readOnly
      />

      {error && <div className="tw-rounded-md tw-bg-red-50 tw-p-4 tw-mt-10">
        <div className="tw-flex">
          <div className="tw-flex-shrink-0">
            <svg className="tw-h-10 tw-w-10 tw-text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="tw-ml-3">
            <h3 className="tw-text-2xl tw-font-medium tw-text-red-800">Invalid Syntax Error</h3>
            <div className="tw-mt-2 tw-text-xl tw-text-red-700">
              <p>
                {error}
              </p>
            </div>
          </div>
        </div>
      </div>}

      <style>
        {`
        .toastui-editor-contents table th {
          background-color: #1AB28F;
        }
        `}
      </style>
    </div>
  );
};

export default MarkdownEditor;
