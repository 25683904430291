import React from 'react';
import MDX from '@mdx-js/runtime';
import Quote from './Quote'
import Stats from './Stats'
import StatsGroup from './StatsGroup'
import LineChart from './LineChart'
import BarChart from './BarChart'
import Alert from './Alert'

import { useEffect } from 'react';

const components = { Quote, Stats, StatsGroup, LineChart, BarChart, Alert };

const MarkdownRenderer = ({ markdown }) => {
  useEffect(() => {
    setTimeout(() => {
      const links = document.querySelectorAll('.canvasjs-chart-credit');
      links.forEach((el) => {
        el.remove();
      });
    }, 100);
  }, []);

  return (
    <div className="tw-mt-10">
      <article className="tw-prose-2xl prose-headings:tw-text-goa prose-thead:tw-bg-goa prose-th:tw-text-white prose-th:tw-p-4 prose-table:tw-border prose-td:tw-border prose-td:tw-p-4 prose-table:tw-mb-10 prose-ul:tw-list-disc prose-ol:tw-list-decimal prose-img:tw-max-w-full prose-img:tw-border prose-img:tw-border-solid prose-img:tw-border-goa">
        <MDX components={components}>
          {markdown}
        </MDX>
      </article>
    </div>
  );
}

export default MarkdownRenderer;

